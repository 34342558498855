<template>
  <b-row>
    <b-col cols="12">
      <b-form-input v-model="search" placeholder="Otsi nime järgi..." class="mb-2"></b-form-input>
      <treeselect
          v-model="filteredTags"
          :multiple="true"
          :options="options"
          :disable-branch-nodes="true"
          placeholder="Otsi tüübi/koostise/kaunistuse/valmistusmeetodi järgi..."/>
    </b-col>
    <b-col class="text-start">
      <b-card no-body class="overflow-hidden m-2" v-for="x of filteredCocktails" :key="x.code">
        <b-row no-gutters>
          <b-col md="3" class="p-md-0">
            <b-card-img
                :src="require(`@/assets/cocktails/${x.code}.jpg`)"
                :alt="`Image of ${x.name}`"
                width="12"
                class="rounded-0"></b-card-img>
          </b-col>
          <b-col md="9" class="p-md-0">
            <b-card-body>
              <b-card-text>
                <h5 class="m-0">{{ x.name }} <small>{{ x.alias ? `(${x.alias.join(", ")})` : "" }}</small></h5>
                <div class="type-info mb-2">({{ x.type.join(", ") }})</div>
                <div v-for="i of processIngredients(x.ingredients)" :key="i">
                  <div> * {{ i }}</div>
                </div>
                <div class="mt-2">
                  {{ x.recipeText }}
                </div>
                <div class="mt-2">
                  {{ x.decorationText }}
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import cocktails from "@/assets/cocktails.json"
import categories from "@/assets/categories.json"

export default {
  name: 'Main',
  components: {
    Treeselect
  },
  data: function () {
    return {
      search: "",
      filteredTags: [],
      cocktails: cocktails,
      options: [categories]
    }
  },
  computed: {
    filteredCocktails: function () {
      return cocktails.filter(x => {
        return x.name.toLowerCase().includes(this.search.toLowerCase()) && this.filteredTags.every(r => x.tags.includes(r))
      })
    }
  },
  methods: {
    processIngredients(ings) {
      let out = []
      for (let i = 0; i < ings.length;) {
        let str = this.ingredientToString(ings[i]);
        i++
        while (ings[i - 1].orNext) {
          str += " / " + this.ingredientToString(ings[i])
          i++
        }
        out.push(str)
      }
      return out
    },
    ingredientToString(i) {
      let out = `${i.amount || ""} ${i.unit || ""} ${i.prefix || ""} ${i.name}`.trim()
      return i.optional ? `(${out})` : out
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.type-info {
  font-size: 0.8rem;
}

.card-text {
  font-size: .9rem;
}

input::placeholder {
  color: #bbb;
}
</style>
